.link{
    
    color: #293060;
    
}

.scritte{
    text-align: center;
    font-size: large;
}

.headerImage{
    width: 100%;
    max-width: 800px;
    max-height: 50%;
    /* position: relative;
    left: 50%;  
    margin-left: -50vw; */
}
.headerDiv{
    text-align: center;
    /* background-color: #2c2428; */
    background-color: #ffffff;

}