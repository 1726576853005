/* .video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    height: 0;
  } */
  
  .video-responsive iframe {
    left: 0;
    top: 0;
    height: 50vh;
    width: 100%;
  }
  